.advantages {
  position: relative;
  overflow: hidden;
  margin: 98px 0;
}
.advantages .advantages__container {
  padding: 0 255px;
}
.advantages .advantages__col {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.advantages .advantages__slide {
  padding: 70px 50px;
}
.advantages .advantages__image-wrapper {
  position: relative;
  max-width: 32px;
  max-height: 32px;
  width: 100%;
  height: 100%;
  padding-top: 16%;
}
.advantages .advantages__image {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.advantages .advantages__icon {
  margin-bottom: 8px;
}
.advantages .advantages__icon svg {
  width: 23px;
  height: 23px;
}
.advantages .advantages__heading {
  margin-bottom: 5px;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.33;
}
.advantages .advantages__text {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.142;
  letter-spacing: 0.02em;
}
.advantages .advantages__button {
  top: 48.2%;
  width: 20px;
  height: 60px;
}
.advantages .advantages__button svg {
  width: 23px;
  height: 60px;
}
.advantages .advantages__button.brands-slider__button--prev {
  left: 3px;
}
.advantages .advantages__button.brands-slider__button--next {
  right: 3px;
}
.advantages .advantages__button::after {
  content: none;
}
.advantages .advantages__pagination {
  z-index: 4;
}
.advantages .advantages__pagination.swiper-pagination-bullets {
  bottom: 64px;
}
.advantages .advantages__pagination .swiper-pagination-bullet {
  display: inline-block;
  opacity: 1;
  width: 12px;
  height: 12px;
  margin: 0 6px;
  border-radius: 50%;
  border: 2px solid #666666;
  background-color: #ffffff;
}
.advantages .advantages__pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: #666666;
}
.advantages .advantages__row-wrapper:not(.swiper-container-initialized) .advantages__row {
  display: flex;
  justify-content: center;
  margin: 0;
}
.advantages .advantages__row-wrapper:not(.swiper-container-initialized) .advantages__col {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 25%;
  width: 100%;
  padding: 0 75px;
}
.advantages .advantages__row-wrapper:not(.swiper-container-initialized) .advantages__button {
  display: none;
}
.advantages .advantages__row-wrapper:not(.swiper-container-initialized) .advantages__pagination {
  display: none;
}
@media (max-width: 1700px) {
  .advantages {
    margin-top: 95px;
    margin-bottom: 95px;
  }
  .advantages .advantages__container {
    padding: 0 200px;
  }
  .advantages .advantages__row-wrapper:not(.swiper-container-initialized) .advantages__col {
    padding: 0 65px;
  }
}
@media (max-width: 1500px) {
  .advantages {
    margin-top: 85px;
    margin-bottom: 85px;
  }
  .advantages .advantages__container {
    padding: 0 150px;
  }
  .advantages .advantages__row-wrapper:not(.swiper-container-initialized) .advantages__col {
    padding: 0 35px;
  }
}
@media (max-width: 1199px) {
  .advantages {
    margin-top: 75px;
    margin-bottom: 75px;
  }
  .advantages .advantages__container {
    padding: 0 100px;
  }
}
@media (max-width: 991px) {
  .advantages {
    margin-top: 65px;
    margin-bottom: 65px;
  }
  .advantages .advantages__container {
    padding: 0 50px;
  }
  .advantages .advantages__row-wrapper:not(.swiper-container-initialized) .advantages__col {
    padding: 0 30px;
  }
  .advantages .advantages__text {
    font-size: 13px;
  }
}
@media (max-width: 767px) {
  .advantages {
    margin-top: 55px;
    margin-bottom: 55px;
  }
  .advantages .advantages__heading {
    font-size: 18px;
  }
  .advantages .advantages__text {
    font-size: 12px;
    line-height: 1.4;
  }
  .advantages .advantages__button::after {
    content: none;
  }
  .advantages .advantages__button {
    height: 40px;
  }
  .advantages .advantages__button.advantages__button--next {
    right: 32px;
  }
  .advantages .advantages__button.advantages__button--prev {
    left: 32px;
  }
  .advantages .advantages__button svg {
    width: 20px;
    height: 40px;
  }
  .advantages .advantages__pagination .swiper-pagination-bullet {
    width: 8px;
    height: 8px;
    margin: 0 5px;
  }
}
@media (max-width: 575px) {
  .advantages {
    margin: 15px 0;
  }
  .advantages .advantages__container {
    padding-top: 0;
    padding-bottom: 20px;
    padding-left: 15px;
    padding-right: 15px;
  }
  .advantages .advantages__slide {
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 20px;
    padding-right: 20px;
  }
  .advantages .advantages__button {
    display: none;
  }
  .advantages .advantages__pagination.swiper-pagination-bullets {
    bottom: 10px;
  }
}
@media (max-width: 380px) {
  .advantages .advantages__heading {
    margin-bottom: 6px;
  }
  .advantages .advantages__text {
    padding: 0 14px;
  }
}